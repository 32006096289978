exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-courses-tsx": () => import("./../../../src/pages/courses.tsx" /* webpackChunkName: "component---src-pages-courses-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-project-post-tsx": () => import("./../../../src/templates/project-post.tsx" /* webpackChunkName: "component---src-templates-project-post-tsx" */),
  "component---src-templates-projects-archive-tsx": () => import("./../../../src/templates/projects-archive.tsx" /* webpackChunkName: "component---src-templates-projects-archive-tsx" */),
  "component---src-templates-publication-post-tsx": () => import("./../../../src/templates/publication-post.tsx" /* webpackChunkName: "component---src-templates-publication-post-tsx" */),
  "component---src-templates-publications-archive-tsx": () => import("./../../../src/templates/publications-archive.tsx" /* webpackChunkName: "component---src-templates-publications-archive-tsx" */)
}

